<template>
  <CabinetPage :preloader="preloader">
    <div class="mx-cabinet-page-header">
      <router-link :to="{name: 'company.show', params: {id: $route.params.company_id}}" class="btn btn-outline-secondary">
        <ph-caret-left :size="22" />
      </router-link>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="mx-form-header"></div>
        <form class="mx-form">
          <div class="mx-modal-form">
            <div class="mb-3">
              <div class="mx-modal-prompt">
                <h3 class="mx-modal-prompt__title">{{ $t('company.person.csv.prompt.title') }}</h3>

                <input type="file" accept="text/csv" style="margin: 15px 0 5px 0;" v-on:change="csvFileChange">
                <span style="color: red; margin-bottom: 50px;" v-for="error in errors" v-if="error.status === 500">
                  {{ error.text }}
                </span>
              </div>
            </div>
          </div>
          <div class="mx-form-buttons" style="justify-content: space-between; align-items: center">
            <div class="mx-modal-form__element" style="margin-top: 20px; margin-left: 50px;">
              <label class="mx-modal-form-switch">
                <input type="checkbox" class="mx-modal-form-switch__input" v-model="delete_all_contact_person">
                <span class="mx-modal-form-switch__slider mx-round"></span>
              </label>
              <label class="form-label">Vorher alle Ansprechpartner löschen </label>
              <br>
              <strong v-if="delete_all_contact_person" class="mx-modal-prompt__subtitle" style="color: #ce3232;">{{ $t('company.person.csv.prompt.subtitle') }}</strong>
            </div>
            <div style="display: flex">
              <router-link :disabled="button_disabled" tag="button" :to="{name: 'company.show', params: {id: $route.params.company_id}}" class="btn btn-peepz-default">
                {{ $t('company.abort') }}
              </router-link>
              <button :disabled="button_disabled || csvFile === null" @click="save" type="button" class="btn btn-peepz-success">
                {{ $t('company.person.csv.load') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </CabinetPage>
</template>

<script>

import CabinetPage from "@/components/main/CabinetPage";
import {PhCaretLeft,} from "phosphor-vue";
import axios from "axios";

export default {
  components: {CabinetPage, PhCaretLeft},
  data() {
    return {
      mobile: false,
      button_disabled: false,
      preloader: true,
      csvFile: null,
      errors: [],
      delete_all_contact_person: false
    }
  },
  mounted() {
    this.mobile = window.innerWidth <= '768'
    this.preloader = false
  },
  methods: {
    save() {
      this.button_disabled = true;

      let formData = new FormData()
      formData.append('id', this.$route.params.company_id)
      formData.append('delete_all_contact_person', this.delete_all_contact_person === true ? 1 : 0)
      formData.append('file', this.csvFile)

      axios.post(`contact-persons/${this.$route.params.company_id}/csv/import`, formData, {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        this.$store.commit('SET_PERSONS_CSV', response.data.data)
        this.$router.replace({
          name: 'company.show', params: {id: this.$route.params.company_id}
        })
      }).catch(error => {
        this.button_disabled = false;
        this.csvFile = null
        this.errors = []
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data;
            break;
          case 500:
            this.errors.push({'status': 500, 'text': this.$t('company.person.csv.errors.500')})
            break;
        }
      })
    },
    csvFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.csvFile = files[0]
      this.errors = []
    }
  }
}
</script>
